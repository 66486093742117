import React from 'react';

const features = [
  {
    number: '1',
    title: 'Expert',
    description:
      'We boast the best pool of faculty members, including IITians and Doctorates, who are not only highly qualified and experienced but also passionate about teaching in a logical and comprehensible manner.',
    icon: '👩‍🏫',
  },
  {
    number: '2',
    title: 'Foundation Builder Programme',
    description:
      'For students in classes VI to X, our Foundation Builder Program aims to develop curiosity and critical thinking, laying a strong foundation for future success through experiential learning and modern teaching methodologies.',
    icon: '🎯',
  },
  {
    number: '3',
    title: 'Adaptive Assessment',
    description:
      'After testing, students receive a detailed assessment report, helping them understand their strengths and weaknesses. This insight allows them to work on areas that need improvement with the guidance of faculty members.',
    icon: '⚛️',
  },
];

const NurturingSection = () => {
  return (
    <section className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-black mb-4">Nurturing Young Minds</h2>
          <p className="text-gray-600">
            At The Ignited Mind (TIM) Classes, we are dedicated to nurturing young minds, turning their curiosity into a vision, and providing the platform and exposure needed for them to be the best they can.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center shadow-sm"
            >
              <div className="relative inline-block mb-4">
                <div className="text-4xl text-blue-700 mb-2">{feature.icon}</div>
                <div className="absolute -top-2 -right-2 bg-blue-700 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">
                  {feature.number}
                </div>
              </div>
              <h3 className="text-xl text-blue-700 font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-600 text-sm">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NurturingSection;
