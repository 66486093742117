import React from "react";

const BookingForm = () => {
  const onSubmit = async (event) => {
    event.preventDefault();

    // Create FormData from the event target (form)
    const formData = new FormData(event.target);

    // Append the required access key
    formData.append("access_key", "20cce2e0-8c97-450e-ba62-02e95bf374ee");

    // Debugging - Log all form data entries
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    // Convert form data to JSON
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      // Make the POST request to Web3Forms
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const resData = await response.json();

      if (resData.success) {
        console.log("Success:", resData);
        alert("Booking successful! We'll get in touch with you soon.");
        event.target.reset();
      } else {
        console.error("Error:", resData);
        alert("There was an issue submitting your booking. Please try again.");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      alert("Network error. Please check your connection.");
    }
  };

  return (
    <div className="bg-gray-50 rounded-lg p-8 shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Book Your Seat Now</h2>
      <form className="space-y-4" onSubmit={onSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700"
          required
        />
        <input
          type="tel"
          name="number"
          placeholder="Your Contact Number"
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700"
          required
        />
        <input
          type="text"
          name="course"
          placeholder="Your Desired Course"
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700"
          required
        />
        <textarea
          name="message"
          rows="5"
          placeholder="Any Additional Information"
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700"
        ></textarea>
        <button
          type="submit"
          className="w-full bg-blue-700 text-white font-bold p-3 rounded-lg hover:bg-blue-600 transition"
        >
          Register Now
        </button>
      </form>
    </div>
  );
};

export default BookingForm;
