import React from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import "../../../components/common/header/header.css";
const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row md:-mt-24 sm:-mt-5 mt-72">
            <Heading title="Your Path to Academic Excellence" />
            <p className="lg:text-xl text-xs sm:flex hidden">
              At The Ignited Mind (TIM) Classes, we pride ourselves on
              instilling dedication, passion, and perseverance in our students
              values that define our exceptional team. Our commitment lies in
              delivering the highest quality of education, ensuring that the
              learning process is not only robust but also enjoyable. We achieve
              this by employing innovative teaching methods that make the
              educational journey both enriching and engaging for aspiring
              students.
            </p>
          </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Hero;
