import React from 'react';

const CourseCard = ({ title, mainTitle, duration, icon }) => {
  return (
    <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center shadow-sm">
      <div className="text-4xl text-blue-700 mb-4">{icon}</div>
      <h3 className="text-xl font-bold mb-2">{mainTitle}</h3>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-gray-500">{duration}</p>
    </div>
  );
};

export default CourseCard;
