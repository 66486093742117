import React, { useState } from "react";
import Head from "./Head";
import "./header.css";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [click, setClick] = useState(false);
  const location = useLocation();

  return (
    <>
      <Head />
      <header>
        <nav className="flexSB">
          <Link to="/">
            <div className="md:flex hidden sm:w-72 p-2 items-start">
              <div>
                <img
                  src="../logo/LogoDesk.png"
                  alt="Academia Logo"
                  className="logo-img  rounded-l-lg "
                />
              </div>
            </div>
          </Link>
          <ul
            className={click ? "mobile-nav" : "flexSB text-["}
            onClick={() => setClick(false)}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/gallery-page">Gallery</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/scholarship">Scholarship</Link>
            </li>
          </ul>
        </nav>
      </header>

      {/* Show "Chat With Us" button only on the home page */}
      {location.pathname === "/" ? (
        <>
          <button className="primary-btn flex xl:mt-[420px] mt-[420px] xl:ml-[125px] md:ml-[70px] ml-[20px]">
            <a
              href="https://wa.me/919321249030"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chat With Us
              <i className="fa fa-long-arrow-alt-right ml-2"></i>
            </a>
          </button>
          <button className="button flex sm:-mt-[59px] md:ml-[310px] ml-[20px]">
            <a href="tel:+918355820658">
              Call Us
              <i className="fa fa-phone ml-2"></i>
            </a>
          </button>
        </>
      ) : (
        <div className="primary-btn flex xl:mt-[420px] mt-[420px] xl:ml-[110px] ml-[20px]"></div>
      )}
    </>
  );
};

export default Header;
