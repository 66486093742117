import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const stats = [
  { number: 1200, text: "Students Coached" },
  { number: 11, text: "Faculty Courses" },
  { number: 7, text: "Best Professors" },
  { number: 140, text: "Selections Achieved" },
];

const reviews = [
  {
    text: "The regular assessments and feedback sessions kept me on track throughout my JEE preparation. Highly recommend their coaching!",
    name: "Saurav Garg",
    role: "IIT Bombay",
    image: "../profile/profile1.jpg",
  },
  {
    text: "The supportive environment and excellent study materials made learning enjoyable and effective. Thank you for helping me succeed!",
    name: "Rohit Kumar",
    role: "AIIMS Nagpur",
    image: "../profile/profile2.webp",
  },
];
const CountUp = ({ end, start = 0, duration = 2000 }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  }, [end, start, duration]);

  return <>{count.toLocaleString()}</>;
};

const Testimonal = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <div>
      {/* Stats Section */}
      <section className="bg-blue-700 py-12">
        <div
          ref={ref}
          className="max-w-7xl mx-auto px-8 grid grid-cols-1 md:grid-cols-4 gap-8 text-center text-white"
        >
          {stats.map((stat, index) => (
            <div key={index}>
              <h2 className="text-4xl font-bold">
                {inView ? <CountUp end={stat.number} duration={1500} /> : "0"} +
              </h2>
              <p className="text-lg">{stat.text}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Reviews Section */}
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-black mb-4">
              Reviews From Students
            </h2>
            <p className="text-gray-600">
              Hear what our students have to say about their journey with us.
              Their success stories are a testament to our commitment and
              dedication.
            </p>
          </div>
          <div className="grid grid-cols md:grid-cols-2 gap-8">
            {reviews.map((review, index) => (
              <div
                key={index}
                className="bg-white border border-gray-200 rounded-lg p-6 shadow-md flex items-start gap-4"
              >
                <div>
                  <img
                    src={review.image}
                    alt={review.name}
                    className="sm:w-44 sm:h-24 h-12 w-32 rounded-full border-2 border-blue-700"
                  />
                </div>
                <div>
                  <p className="text-gray-700 text-sm mb-4">{review.text}</p>
                  <div>
                    <h4 className="text-black font-bold">{review.name}</h4>
                    {/* <p className="text-gray-500 text-sm">{review.role}</p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonal;
