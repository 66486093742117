import React, { useState } from "react";
import { Link } from "react-router-dom";

const Head = () => {
  const [click, setClick] = useState(false);

  return (
    <>
      <div className="container relative z-10 flex justify-between items-center">
        <div className="md:hidden flex  lg:h-36 -mt-4">
          <Link to="/">
            <div className="flex h-24 sm:w-72 items-start">
              <div>
                <img
                  src="../logo/6.png"
                  alt="Academia Logo"
                  className="logo-img sm:ml-0 ml-4 sm:h-[150px] sm:w-[150px] rounded-l-lg "
                />
              </div>
            </div>
          </Link>
        </div>

        {/* Toggle Button for Mobile */}
        <div
          className="md:hidden cursor-pointer "
          onClick={() => setClick(!click)}
        >
          {click ? (
            <i className="fa fa-times text-white text-3xl"></i>
          ) : (
            <i className="fa fa-bars text-white text-3xl"></i>
          )}
        </div>
      </div>

      {/* Navigation Menu */}
      {click && (
        <header>
          <div
            className={`fixed z-10 top-0 right-0 h-screen w-64 bg-white shadow-lg transform ${
              click ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
          >
            {/* Close Button */}
            <div className="p-4 flex justify-end">
              <div
                className="text-gray-500 hover:text-gray-800 text-2xl cursor-pointer"
                onClick={() => setClick(false)}
              >
                <i className="fa fa-times"></i>
              </div>
            </div>

            {/* Navigation Menu */}
            <nav className="flex flex-col mt-6">
              <Link
                to="/"
                className="text-lg text-gray-700 hover:text-blue-600 px-6 py-3 transition-colors duration-200"
                onClick={() => setClick(false)}
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-lg text-gray-700 hover:text-blue-600 px-6 py-3 transition-colors duration-200"
                onClick={() => setClick(false)}
              >
                About Us
              </Link>
              <Link
                to="/gallery-page"
                className="text-lg text-gray-700 hover:text-blue-600 px-6 py-3 transition-colors duration-200"
                onClick={() => setClick(false)}
              >
                Gallery
              </Link>
              <Link
                to="/contact"
                className="text-lg text-gray-700 hover:text-blue-600 px-6 py-3 transition-colors duration-200"
                onClick={() => setClick(false)}
              >
                Contact
              </Link>
              <Link
                to="/scholarship"
                className="text-lg text-gray-700 hover:text-blue-600 px-6 py-3 transition-colors duration-200"
                onClick={() => setClick(false)}
              >
                Scholarship
              </Link>
            </nav>
          </div>
        </header>
      )}

      {/* Desktop Navigation (Hidden on Mobile) */}
    </>
  );
};

export default Head;
