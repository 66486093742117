import React from "react";
import BookingForm from "./BookingForm";
import CourseCard from "./CourseCard";

const CoursesOffered = () => {
  const courses = [
    {
      title: "JEE Mains | Advance ",
      mainTitle: "Engineering",
      // duration: "2 Years",
      icon: "👷",
    },
    { title: "CET", icon: "💻" },
    { title: "NEET ", mainTitle: "Medical",  icon: "🔬" },
    {
      title: "11th & 12th Board (CBSE, ICSE & State Boards)",
      // duration: "1 Year",
      icon: "👫",
    },
    {
      title: "8th , 9th & 10th (CBSE, ICSE & State Boards)",
      // duration: "1 Year",
      icon: "✏️",
    },
  ];
  return (
    <div className="bg-white font-sans">
      <div className="max-w-7xl mx-auto p-8">
        <h1 className="text-3xl font-bold text-black mb-6">
          Courses Offered at{" "}
          <span className="text-blue-700">The Ignited Mind (TIM) Classes</span>
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {courses.map((course, index) => (
            <CourseCard
              key={index}
              mainTitle={course.mainTitle}
              title={course.title}
              duration={course.duration}
              icon={course.icon}
            />
          ))}
        </div>
        <div className="mt-10">
          <BookingForm />
        </div>
      </div>
    </div>
  );
};

export default CoursesOffered;
