import React from "react";
import Back from "../common/back/Back";

const Contact = () => {
  const map =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3716.24642463848!2d72.89122002451155!3d20.393703614105434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0d0214d6e8ab3%3A0x2db7b2ee7334fc5d!2sV2%20Signature%2C%20Chala%2C%20Vapi%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1735472303348!5m2!1sen!2sin";

    const onSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
  
      formData.append("access_key", "20cce2e0-8c97-450e-ba62-02e95bf374ee");
  
      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);
  
      try {
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        });
        const result = await res.json();
  
        if (result.success) {
          alert("Message sent successfully!");
          event.target.reset(); 
        } else {
          alert(`Error: ${result.message}`);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("Failed to send the message. Please try again later.");
      }
    };

  return (
    <>
      <Back title="Contact us" />
      <section className="contacts sm:py-52 py-8">
        <div className="mx-auto sm:px-8 lg:px-16">
          <div className="flex flex-col lg:flex-row sm:px-0 px-6 shadow-lg rounded-2xl overflow-hidden">
            {/* Left Side: Map */}
            <div className="w-full lg:w-1/2 border-2 rounded-2xl sm:mt-0 mt-24">
              <iframe
                src={map}
                width="90%"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="border-blue-300"
              ></iframe>
            </div>

            {/* Right Side: Contact Info */}
            <div className="w-full lg:w-1/2 sm:p-8 p-2 space-y-8">
              <h1 className="text-3xl font-bold text-gray-800">Contact Us</h1>
              <p className="text-gray-600">
                We're open for any suggestions or just to have a chat.
              </p>

              <div className="space-y-6">
                {/* Phone Number */}
                <a href="tel:+918355820658" className="block">
                  <div className="flex items-center space-x-4 p-4 border rounded-lg shadow hover:bg-gray-100 transition">
                    <div className="bg-blue-700 text-white p-4 rounded-lg">
                      <i className="fas fa-phone-alt text-2xl"></i>
                    </div>
                    <div>
                      <h4 className="text-lg font-bold">Phone Number:</h4>
                      <p className="text-gray-600">
                        +91-8355820658, +91-9321249030
                      </p>
                    </div>
                  </div>
                </a>

                {/* Location Address */}
                <a
                  href="https://www.google.co.in/maps/search/Office+No.+138,+1st+Floor,+V2+Signature+Building,+Chala,+Vapi,+Gujarat+-+396191"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block"
                >
                  <div className="flex items-center space-x-4 p-4 border rounded-lg shadow hover:bg-gray-100 transition">
                    <div className="bg-blue-700 text-white p-4 rounded-lg">
                      <i className="fas fa-map-marker-alt text-2xl"></i>
                    </div>
                    <div>
                      <h4 className="text-lg font-bold">Location Address:</h4>
                      <p className="text-gray-600">
                        Office No. 138, 1st Floor, V2 Signature Building, Chala,
                        Vapi, Gujarat - 396191
                      </p>
                    </div>
                  </div>
                </a>

                {/* Email Address */}
                <a href="mailto:theignitedmind06@gmail.com" className="block">
                  <div className="flex items-center space-x-4 p-4 border rounded-lg shadow hover:bg-gray-100 transition">
                    <div className="bg-blue-700 text-white p-4 rounded-lg">
                      <i className="fas fa-envelope text-2xl"></i>
                    </div>
                    <div className="max-w-md mx-auto">
                      <h4 className="text-lg font-bold">Email Address:</h4>
                      <p className="text-gray-600 break-words">
                        theignitedmind06@gmail.com
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              {/* Form */}
              <form className="space-y-4" onSubmit={onSubmit}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="p-3 border rounded-lg w-full focus:outline-none focus:ring focus:ring-blue-200" required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="p-3 border rounded-lg w-full focus:outline-none focus:ring focus:ring-blue-200"
                    required
                  />
                </div>
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  className="p-3 border rounded-lg w-full focus:outline-none focus:ring focus:ring-blue-200"
                  required

                />
                <textarea
                  rows="5"
                  name="message"
                  placeholder="Create a message here..."
                  className="p-3 border rounded-lg w-full focus:outline-none focus:ring focus:ring-blue-200" required
                ></textarea>
                <button
                  type="submit"
                  className="bg-blue-700 text-white py-3 px-6 rounded-lg hover:bg-blue-500 transition"
                >
                  SEND MESSAGE
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
