import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <footer className="bg-blue-700 text-white py-10 px-6 lg:px-20">
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div>
            <h1 className="text-3xl font-bold">
              The Ignited Mind (TIM) Classes
            </h1>
            <p className="text-sm mt-4 leading-relaxed">
              The Ignited Mind (TIM) Classes, a pioneering initiative led by
              seasoned intellectuals, is dedicated to guiding students in Vapi
              towards excellence in competitive examinations such as IIT-JEE,
              NEET, and Olympiads.
            </p>
            <div className="flex space-x-4 mt-4">
              <a
                href="https://www.instagram.com/theignitedmind_official/?hl=en"
                className="text-white text-xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.facebook.com/people/TIM-Classes-The-Ignited-Mind/61571730781912/?rdid=3s2ZQixDdqll9Oky&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F1GgbGv4EpJ%2F"
                className="text-white text-xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </div>
          </div>

          <div>
            <h2 className="text-lg font-semibold whitespace-nowrap">
              Quick Links
            </h2>
            <ul className="mt-4 space-y-2">
              <li>
                <Link to="/" className="hover:underline whitespace-nowrap" onClick={scrollToTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:underline whitespace-nowrap" onClick={scrollToTop}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/gallery-page" className="hover:underline whitespace-nowrap" onClick={scrollToTop}>
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:underline whitespace-nowrap" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-lg font-semibold">Operating Hours:</h2>
            <p className="mt-4 text-sm">9 AM - 9 PM, Monday - Sunday</p>
            <p className="text-sm mt-2 leading-relaxed">
              The Ignited Mind (TIM) Classes is open Monday through Sunday from
              9:00 AM to 9:00 PM, ensuring accessibility for academic growth and
              support.
            </p>
            <button className="mt-4 py-2 px-6 text-black font-bold rounded-lg shadow hover:bg-yellow-600">
              <a href="tel:+918355820658" className="">
                Call Us Now
              </a>
            </button>
          </div>
        </div>
        <p className="text-center text-sm mt-10">
          Copyright © 2025 The Ignited Mind (TIM) Classes. All rights reserved.
        </p>
      </footer>
    </>
  );
};

export default Footer;
