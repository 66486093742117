import React from "react";
import Hero from "./hero/Hero";
import Testimonal from "./testimonal/Testimonal";
import StudentSatisfaction from "./StudentSatisfaction";
import CoursesOffered from "./CoursesOffered";
import NurturingSection from "./NurturingSection";

const Home = () => {
  return (
    <>
      <Hero />
      <StudentSatisfaction/>
      <CoursesOffered/>
      <NurturingSection/>
      <Testimonal />

    </>
  );
};

export default Home;
