import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const CountUp = ({ end, start = 0, duration = 2000 }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  }, [end, start, duration]);

  return <span>{count.toLocaleString()}</span>;
};

const StudentSatisfaction = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  const secondaryImages = [
    "../homepage/scrollimage/2.jpeg",
    "../homepage/scrollimage/3.jpg",
    // "../homepage/other/1.jpg",
   
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % secondaryImages.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [secondaryImages.length]);

  return (
    <div className="p-6 lg:p-12 bg-white">
      {/* Section: Main Content */}
      <div className="flex flex-col lg:flex-row items-start gap-10">
        {/* Left Column: Image and Stats */}
        <div className="flex flex-col gap-8">
          {/* Image */}
          <div className="overflow-hidden rounded-lg shadow-lg">
            <img
              src="../homepage/scrollimage/1.jpg"
              alt="Students"
              className="w-full h-auto rounded-lg"
            />
          </div>
          {/* Stats */}
          <div>
            <div ref={ref} className="text-red-600 font-bold text-lg mb-2">
              Student Satisfaction:{" "}
              {inView ? <CountUp end={100} duration={2000} /> : "0"}%
            </div>
            <div className="h-1 bg-red-600 w-full"></div>

            <div className="text-red-600 font-bold text-lg mt-4 mb-2">
              Happy Parents: {inView ? <CountUp end={100} duration={2000} /> : "0"}%
            </div>
            <div className="h-1 bg-red-600 w-full"></div>
          </div>
        </div>

        {/* Right Column: Content */}
        <div className="flex flex-col gap-8">
          <h2 className="text-3xl font-bold text-gray-800">
            Why Do Students Prefer{" "}
            <span className="text-blue-700">The Ignited Mind (TIM) Classes?</span>
          </h2>
          <p className="text-gray-600 text-lg">
            At The Ignited Mind (TIM) Classes, we understand that a student
            embarks on their academic journey with a goal, and we are committed
            to ensuring that every student reaches their destination. The reasons
            why science aspirants should choose The Ignited Mind (TIM) Classes
            are diverse:
          </p>
          {/* Secondary Image Carousel */}
          <div
            className="overflow-hidden rounded-lg shadow-lg w-full h-[450px]" // Fixed height here
          >
            <img
              src={secondaryImages[currentImageIndex]}
              alt={`Classroom ${currentImageIndex + 1}`}
              className="w-full h-full transition-all duration-500"
            />
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-12">
        {/* Feature 1 */}
        <div className="flex items-start gap-4">
          <div className="flex items-center justify-center w-16 h-12 bg-blue-100 rounded-full">
            <i className="fas fa-user-graduate text-blue-700 text-xl"></i>
          </div>
          <div>
            <h3 className="text-lg font-bold text-blue-700">Personalised Coaching</h3>
            <p className="text-gray-600 text-sm">
              Recognizing that every student is different, we provide personalized
              coaching to help students grasp concepts at their own pace.
            </p>
          </div>
        </div>
        {/* Feature 2 */}
        <div className="flex items-start gap-4">
          <div className="flex items-center justify-center w-20 h-12 bg-blue-100 rounded-full">
            <i className="fas fa-book-open text-blue-700 text-xl"></i>
          </div>
          <div>
            <h3 className="text-lg font-bold text-blue-700">Rigorous Testing</h3>
            <p className="text-gray-600 text-sm">
              We conduct various types of tests, including topic-wise tests, major
              tests, and mock tests, to build students’ confidence and familiarize
              them with exam patterns.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentSatisfaction;
