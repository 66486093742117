import React, { useEffect, useState } from "react";
import Back from "../common/back/Back";

const GalleryPage = () => {
  // Categories with images and videos
  const galleryData = {
    Classrooms: [
      "../classrooms/1.jpg",
      "../classrooms/2.jpg",
      "../classrooms/3.jpg",
      "../classrooms/5.jpg",
      "../classrooms/6.jpg",
      "../classrooms/7.jpg",
      "../classrooms/IMG_0990.MOV",
    ],
    "Office Premises": [
      "../office/1.mp4",
      "../office/2.jpg",
      "../office/3.jpg",
      "../office/4.jpg",
    
    ],
    Events: [
      "../events/1.mp4",
      "../events/2.mp4",
      "../events/3.jpg",
    ],
  };

  const [selectedCategory, setSelectedCategory] = useState("Classrooms");
  const [visibleIndices, setVisibleIndices] = useState([]);

  useEffect(() => {
    // Reset visibleIndices when the category changes
    setVisibleIndices([]);
    const handleScroll = () => {
      const windowHeight = window.innerHeight;

      document.querySelectorAll(".gallery-item").forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        if (rect.top <= windowHeight - 50) {
          setTimeout(() => {
            setVisibleIndices((prev) =>
              prev.includes(index) ? prev : [...prev, index]
            );
          }, index * 200); // Stagger effect
        }
      });
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Trigger the handleScroll to show items already in view
    handleScroll();

    return () => {
      // Remove scroll event listener on cleanup
      window.removeEventListener("scroll", handleScroll);
    };
  }, [selectedCategory]);

  // Helper to check if the source is a video file
  const isVideo = (src) =>
    /\.(mp4|mov|MOV|webm|avi|mkv|flv|wmv)$/.test(src.toLowerCase());

  return (
    <>
      <Back title="Gallery" />
      <div className="bg-gray-50 min-h-screen py-10 px-6 lg:px-20">
        {/* <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">
          Our Gallery
        </h1> */}

        {/* Category Selection */}
        <div className="flex justify-center mb-8 space-x-4">
          {Object.keys(galleryData).map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-6 py-2 sm:text-lg text-sm font-semibold rounded-md ${
                selectedCategory === category
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Gallery */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {galleryData[selectedCategory].map((src, index) => (
            <div
              key={index}
              className={`gallery-item rounded-sm overflow-hidden transform transition-opacity duration-700 ${
                visibleIndices.includes(index) ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
            >
              {isVideo(src) ? (
                <video
                  controls
                  className="w-full object-cover rounded-2xl"
                  src={src}
                  alt={`Video ${index + 1}`}
                />
              ) : (
                <img
                  src={src}
                  alt={`Image ${index + 1}`}
                  className="w-full object-cover rounded-2xl"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GalleryPage;
