import React, { useState } from "react";
import Back from "../common/back/Back";

const ScholarshipPage = () => {
  const [formStatus, setFormStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    formData.append("access_key", "20cce2e0-8c97-450e-ba62-02e95bf374ee");


    const formObject = Object.fromEntries(formData);
    const json = JSON.stringify(formObject);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: json,
      });

      const result = await response.json();

      if (result.success) {
        setFormStatus("Application submitted successfully!");
        event.target.reset();
      } else {
        setFormStatus("Failed to submit the application. Please try again.");
      }
    } catch (error) {
      setFormStatus("An error occurred. Please try again.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Back title="T-SAT" />

      <div className="bg-gray-50 min-h-screen py-10 px-6 lg:px-20">
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="flex-1 bg-white shadow-lg rounded-xl p-8">
            <h1 className="text-4xl font-bold text-gray-800 mb-8 leading-snug">
              Unlock Your Child’s Bright Future with{" "}
              <span className="text-blue-700">
                The Ignited Mind (TIM) Classes T-SAT
              </span>
              !
            </h1>
            <div className="grid grid-cols-2 gap-6 text-gray-700 text-lg mb-8">
              <div>
                <strong>Location:</strong> Office No. 138, 1st Floor, V2
                Signature Building, Chala, Vapi, Gujarat - 396191
              </div>
              <div>
                <strong>Scholarship Worth:</strong> ₹5 Lakhs
              </div>
              <div>
                <strong>Scholarship Up to:</strong> 90%
              </div>
              <div>
                <strong>Registration:</strong> FREE
              </div>
            </div>
            <img
              src="https://cdn.pixabay.com/photo/2023/02/17/09/01/man-7795548_1280.jpg"
              alt="Classroom"
              className="rounded-lg shadow-md w-full mb-8"
            />
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              Introducing T-SAT
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              Prepare your child for academic excellence with the{" "}
              <strong>
                The Ignited Mind (TIM) Classes Scholarship Aptitude Test (T-SAT)
              </strong>
              . Designed by Experts, this test secures up to 90% Scholarships
              for students from Class 8th to 11th. Take the next step in their
              educational journey today!
            </p>
          </div>

          <div className="w-full lg:w-1/3 bg-white shadow-lg rounded-xl p-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              Registration Form
            </h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name*"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email*"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone Number*"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="tel"
                name="alt_phone"
                placeholder="Alternate Contact Number"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="school"
                placeholder="Your School*"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <select
                name="board"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="">Your Board*</option>
                <option value="cbse">CBSE</option>
                <option value="icse">ICSE</option>
                <option value="state">State</option>
              </select>
              <input
                type="date"
                name="exam_date"
                placeholder="Exam Date"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button
                type="submit"
                className="w-full py-4 bg-blue-700 text-white font-bold text-lg rounded-lg hover:bg-blue-500 transition duration-300"
              >
                SEND APPLICATION
              </button>
            </form>
            {formStatus && (
              <p
                className={`mt-4 text-lg ${
                  formStatus.includes("success")
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {formStatus}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScholarshipPage;
